<template>
  <section class="page-wrap">
    <!-- 输入框 start -->
    <div class="input-wrap">
      <el-input
        v-model="sheetOrder"
        placeholder="在此输入供应商名称或者货号"
        ref="refSheetOrder"
        autofocus="true"
        @keyup.enter.native="onHandleSearch"
      >
        <el-button
          slot="suffix"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="onHandleSearch"
          >确定</el-button
        >
      </el-input>
    </div>
    <!-- 输入框 end -->

    <!-- 供货单布局 start -->
    <div v-if="isShowInfo" class="auto-table-flex " >
      <el-table :data="tabDataList" v-loading="loading" :header-cell-style="{ color: '#333333', background: '#EFF6FF' }" height="100%" style="width: 100%">
        <el-table-column
          type="index"
          width="100px"
          align="center"
          label="序号"
        ></el-table-column>
        <el-table-column
          label="供货单"
          prop="supplier_order_no"
          minWidth="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="供应商名称"
          prop="supplier.fullname"
          minWidth="200"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row?.supplier?.fullname || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="供应商电话"
          prop="supplier.mobile"
          minWidth="200"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row?.supplier?.mobile || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="生成日期"
          prop="create_time"
          minWidth="150"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="fy" v-if="isShowInfo" >
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 供货单布局 end -->

    <!-- 缺省 start -->
    <c-empty-page v-else tip="请输入货号！"></c-empty-page>
    <!-- 缺省 end -->


  </section>
</template>

<script>
import CEmptyPage from "@/components/common/CEmptyPage";
import { getSupplyOrderBySku } from "@/api/logisticsControl/parking-space";

export default {
  name: "LogisticsSkuSearchLog",
  data() {
    return {
      loading: false,
      // 供货单编号
      sheetOrder: "",
      sheetOrdercopy:'',
      tabDataList: [], // 列表数据
      // 是否展示打印详情
      isShowInfo: false,
      currentPage: 1,
      total:0,
      from: {
        page: 1,
        pageSize: 20,
      },
    };
  },
  components: {
    CEmptyPage,
  },
  mounted() {
    this.$refs.refSheetOrder.focus();
  },
  methods: {
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    async  hqlist(){
      this.loading = true;
      const params = {
        skuCode: this.sheetOrdercopy,
        ...this.from
      };
      try {
        const { data } = await getSupplyOrderBySku(params);
        // this.sheetOrder = "";
        this.tabDataList = data.data;
        this.total = data.total;
        this.isShowInfo = true;
        console.log("ajax getSupplyOrderBySku", data);
      } catch (err) {
        console.log("ajax getSupplyOrderBySku err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 查询供货单相关
     */
    async onHandleSearch() {
      if (!this.sheetOrder) {
        this.$message.error("请输入货号！");
        return;
      }
      this.sheetOrdercopy=this.sheetOrder
      this.currentPage=0;
      this.total=0;
      this.from= {
        page: 1,
        pageSize: 20,
      }
    this.hqlist()
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  height: 100%;
  padding: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  .input-wrap {
    padding-bottom: 6px;
    /deep/ .el-input {
      position: relative;
      &__inner {
        font-size: 16px;
        height: 45px;
        line-height: 45px;
        &::placeholder {
          // font-size: 18px;
          padding-left: 10px;
        }
      }
      &__suffix {
        top: 5px;
        right: 20px;
      }
    }
    /deep/ .el-button {
      padding: 0 15px;
      height: 34px;
      .el-icon-search {
        font-size: 12px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .table-wrap {
    height: calc(100% - 60px);
    padding: 20px;
    overflow-y: auto;
    box-sizing: border-box;
    background: #fff;
  }


  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
